<script>
import CustomizationSubmenu from '@/components/workspace/businessWorkspace/WorkspaceManagement/CustomizationSubmenu'
import Banner from '@/components/general/Banner'
export default {
  name: 'HomepageUserCustomization',
  components: { Banner, CustomizationSubmenu }
}
</script>
<template>
  <div class="homepage-user-customization--container">
    <banner
      background-color="secondary-lightest"
      background-figure="/assets/images/workspace-banners/customization.png"
      background-position="center right"
    >
      <div class="homepage-user-customization--banner__wrapper">
        <h5>{{ $t('home.user.customization.banner:title') }}</h5>
        <p class="body--1">{{ $t('home.user.customization.banner:subtitle') }}</p>
      </div>
    </banner>
    <customization-submenu/>
  </div>
</template>
<style lang="scss">
.homepage-user-customization--container {
  .homepage-user-customization--banner__wrapper {
    text-align: left;
    padding: 50px 24px;
    > * {
      margin: 0;
    }
  }
}
</style>
